import '../modals/AddDiseaseModal.css'

import { useState } from 'react';

import { toast } from "react-toastify";
import { axiosInstance } from '../../utils/axios.instance';


const AddDiseaseModal = props => {

    const [nom, setNom] = useState("");
    const [desc, setDesc] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        axiosInstance.post(
            '/maladies',
            {
                nom,
                description: desc
            }
        ).then(res => {
            props.onClose({ nom, desc, id: res.data.maladie.id });

            toast("Successfully added disease", {
                type: toast.TYPE.SUCCESS,
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }

    if (!props.show) {
        return null;
    }


    return (
        <div className="add-modal">
            <div className="add-modal-content">
                <div className="add-modal-header">
                    <h4 className="add-modal-title">Add Disease</h4>
                    <button onClick={props.onClose}>x</button>
                </div>
                <div className="add-modal-body">
                    <form action="" onSubmit={handleSubmit}>
                        <label>Disease name:</label><br />
                        <input type="text" placeholder="Enter the name of the disease" name="" value={nom} onChange={(e) => setNom(e.target.value)} />
                        <br /><br />
                        <label>Disease description:</label>
                        <input type="text" placeholder="Enter the description of the disease" name="" value={desc} onChange={(e) => setDesc(e.target.value)} />

                        <div className="add-modal-footer">
                            <button type="button" className="close-button" onClick={props.onClose}>close</button>
                            <button type="submit" className="submit-button">submit</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default AddDiseaseModal;