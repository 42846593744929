
import './ModifyAssistantModal.css'


const ModifyDiseaseModal = modif => {

    if(!modif.show){
        return null;
    }

    return ( 
        <div className="modify-modal">
        <div className="modify-modal-content">
            <div className="modify-modal-header">
                <h4 className="modify-modal-title">Modify Doctor</h4>
                <button onClick={modif.onClose}>x</button>
            </div>
            <div className="modify-modal-body">
                <form action="">
                    <label>Login:</label>
                    <input type="email" placeholder="example@email.com" name="" />
                    <br />
                    <label>Password:</label>
                    <input type="password" placeholder="Password" name=""/>
                    <br />
                    <label>First name:</label>
                    <input type="text" placeholder="First name" name=""/>
                    <br />
                    <label>Last name:</label>
                    <input type="text" placeholder="Last name" name=""/>
                    <br />
                </form>
            </div>
            <div className="modify-modal-footer">
                <button type="button" className="close-button" onClick={modif.onClose}>close</button>
                <button type="submit" className="submit-button">update</button>
            </div>
        </div>
    </div>
     );
}
 
export default ModifyDiseaseModal;