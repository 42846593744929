import '../../components/landing-page-values/values.css'
import Security from '../../assets/security.png'
import Speed from '../../assets/speed.png'
import Communication from '../../assets/communicate.png'
import Tech from '../../assets/tech.png'
import Bot from '../../assets/bot.png'
import Availability from '../../assets/availability.png'

const Values = () => {
    return ( 
        <div className="values-row" id="values">
           <div className="values-content">
               <div className="values-content-header">
                     <h6>- our values -</h6>
                    <h3>What makes us <br /> similar</h3>
                    <p>We both share the same <br /> vision of developping the <br /> medical sector. <br />
                        This is what we work on.</p>
               </div>
                  
                <div className="values-content-cards">
                    <div className="card-first-row">
                        <div className="cards">
                            <img src={Security} alt="security" />
                            <h4>security</h4>
                            <p>Trust is earned. Our platform ensures a full security that builds a strong, longlasting relationship with our clients.</p>
                        </div>
                        <div className="cards">
                            <img src={Speed} alt="security" />
                            <h4>speed</h4>
                            <p>High speed is in our arsenal, guaranteeing a full time accesibility.</p>
                        </div>
                        <div className="cards">
                            <img src={Communication} alt="communicate" />
                            <h4>communication</h4>
                            <p>We work on strengthening our community by proposing a flux optimisation between the uers.</p>
                        </div>
                    </div>
                    <div className="card-second-row">
                        <div className="cards">
                            <img src={Tech} alt="tech" />
                            <h4>tech support</h4>
                            <p>An attentive 24/7 tech support that covers the issues of the users and meet their needs.</p>
                        </div>
                        <div className="cards">
                            <img src={Bot} alt="Bot" />
                            <h4>ai diagnostic</h4>
                            <p>Switching from the standard diagnostic system to a full, automated ai support that saves time on testing.</p>
                        </div>
                        <div className="cards">
                            <img src={Availability} alt="Availability" />
                            <h4>availability</h4>
                            <p>A powerful app that satisfies our customers and enabling them to use the platform whenever they desire.</p>
                        </div>
                    </div>
                </div>
             
           </div>
        </div>
     );
}
 
export default Values;