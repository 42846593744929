import React from 'react';
import AdminNavbar from '../../components/AdminNavbar';
import DiseaseMain from '../../components/disease-restriction-main/DiseaseMain';
import './css/main.css';
import { useState, useEffect } from 'react';
import { axiosInstance } from '../../utils/axios.instance';
import ExpertNavbar from '../../components/ExpertNavbar';
import DiseaseMainExp from '../../components/disease-restriction-expert/DiseaseMainExp';

const DiseaseRestriction = () => {

    // loading
    const [loading, setLoading] = useState(false);
    const [disease, setDisease] = useState([]);
    const [searchApiData, setSearchApiData] = useState([]);

    const [adminProfile, setAdminProfile] = useState({});

    // load data
    useEffect(() => {
        setLoading(true);
        
                axiosInstance.get('/maladies')
                    .then(res => {
                        setDisease(res.data.maladies);
                        console.log(res.data);
                        setLoading(false);
                    })
                    .catch(err => {
                        console.log(err);
                        setLoading(false);
                    });

            })


    return (
        <div className="row">
            <ExpertNavbar />
            {
                loading ?
                    <div className="loading">
                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                    :
                    <DiseaseMainExp disease={disease} setDisease={setDisease} profile={adminProfile} />
            }
        </div>
    );
}

export default DiseaseRestriction;