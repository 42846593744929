import '../../pages/doctor-homepage/css/doctorHomepage.css';
import DoctorNavBar from "../../components/doctor-navbar/DoctorNavbar";
import DoctorHomepageMain from '../../components/doctor-homepage-main/DoctorHomepageMain';
import IaMain from '../../components/ia-assistant-main/IaMain'
const DoctorAi = () => {
    return ( 
        <div className="row-doctor">
            <DoctorNavBar/>
            <IaMain/>
        </div>
     );
}
 
export default DoctorAi;