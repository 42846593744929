import './AddExpertModal.css'
import { useState } from 'react';
import { toast } from "react-toastify";
import { axiosInstance } from '../../../utils/axios.instance';

const AddExpertModal = props => {

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    const addExperts = (e) => {
        e.preventDefault();
        axiosInstance.post('/experts', {
            email : login,
            password : password
          })
          .then(res => {
            props.onClose({ login,password });

            toast("Successfully added expert", {
                type: toast.TYPE.SUCCESS,
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
        
    }

    if(!props.show){
        return null;
    }


    return ( 
        <div className="add-modal">
        <div className="add-modal-content">
            <div className="add-modal-header">
                <h4 className="add-modal-title">Add Expert</h4>
                <button onClick={props.onClose}>x</button>
            </div>
            <div className="add-modal-body">
                <form action="" onSubmit={addExperts}>
                <div className='form_sep_fir'>
                        <table className='form_tab_fir'>
                            <tr>
                                <td><label>Login:</label></td>
                            </tr>
                            <tr>
                                <td><input type="text" placeholder="example@email.com" className='f_mail' value={login} onChange={(e) => setLogin(e.target.value)} name=""/></td>
                            </tr>
                        </table>
                    </div>
                    <br />
                    <div className='form_sep'>
                        <table className='form_tab'>
                            <tr>
                                <td><label>Password:</label></td>
                                <td><label>Confirm password:</label></td>
                            </tr>
                            <tr>
                                <td><input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} name=""/></td>
                                <td><input type="password" placeholder="Confirm password" name=""/></td>
                            </tr>
                        </table>
                    </div>
                    <br/>
                    <div className="add-modal-footer">
                <button type="button" className="close-button" onClick={props.onClose}>close</button>
                <button type="submit" className="submit-button">Add Expert</button>
            </div>
                </form>
            </div>
            
        </div>
    </div>
     );
}
 
export default AddExpertModal;