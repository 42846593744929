import '../../components/doctor-settings-main/DoctorSettingsMain.css'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../utils/axios.instance';
import { useEffect, useState } from 'react';
import DoctorImage from '../../assets/doctor_image.png'
const DoctorSettingsMain = modify => {

    const [login, setLogin] = useState("");
    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        axiosInstance.get('/medecins/' + modify.id).then(res => {
            setLogin(res.data.email);
            setfirstName(res.data.firstName);
            setlastName(res.data.lastName);
            setPassword(res.data.specialite);
        })
    }, [modify.id]);

    const handleSubmit = (e) => {
        e.preventDefault();

        axiosInstance
        .put('/medecins/' + modify.id, {
            id: modify.id,
            firstName: firstName,
            lastName: lastName,
            email: login,
            password: password
        })
        .then(res => {
            console.log("UPDATE---------------", res.data);

            toast("Updated doctors", {
                type: toast.TYPE.SUCCESS,
                position: toast.POSITION.BOTTOM_CENTER
            })

            modify.onClose({
                firstName: firstName,
                lastName: lastName,
                email: login,
                password: password
            });
            
        })
        .catch(err => {
            console.log(err.message)
        })
    }


    return ( 
        <div className="doctor-settings-col-9">
            <header className="doctor-settings-header">
                    <h3>Doctor Settings</h3>
                    <h5>Welcome, John!</h5>
            </header>

            <div className="doctor-settings-info-display">
                <div className="doctor-img">
                    <img src={DoctorImage} alt="doctor" />
                </div>
                <div className="doctor-info">
                    <h4>WELCOME TO YOUR PERSONAL SETTINGS SECTION</h4>
                    <h5>Dr John</h5>
                    <p>Registered doctor at inquisitio</p>
                </div>
            </div>

            <div className="doctor-settings-credentials">
                <h4>Accounts credentials</h4>
                <form action="" onSubmit={handleSubmit}>
                    <div className="doctor-email">
                        <label>Email</label>
                        <br />
                        <input type="email" placeholder="example@gmail.com" name="email" value={login} onChange={(e) => setLogin(e.target.value)}/>
                    </div>
                    <div className="doctor-names">
                        <div className="doctor-first-name">
                            <label>First Name</label>
                            <br />
                            <input type="text" placeholder="John" name="first-name" value={firstName} onChange={(e) => setfirstName(e.target.value)}/>
                        </div>
                        <div className="doctor-last-name">
                            <label>Last Name</label>
                            <br />
                            <input type="text" placeholder="Doe" name="last-name" value={lastName} onChange={(e) => setlastName(e.target.value)}/>
                        </div>
                    </div>
                    <div className="doctor-passwords">
                        <div className="doctor-old-password">
                            <label>Old Password</label>
                            <br />
                            <input type="password" placeholder="old password" name="old-password" value={password}/>
                        </div>
                        <div className="doctor-new-password">
                            <label>New Password</label>
                            <br />
                            <input type="password" placeholder="new password" name="new-password" onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                    </div>
                    <button type="submit" className="doctor-credentials-button">update</button>
                </form>
                
            </div>

        </div>
     );
}
 
export default DoctorSettingsMain;