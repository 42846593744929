import "../landing-page-header/header.css";
import Logo from "../../assets/logo.png";
import sound from "../../assets/sound.png";
import { useState } from 'react';
import SignInModal from '../../components/modals/SignInModal'
const Header = () => {

  const [signin, setSignin] = useState(false) 

  return (
    <div className="landing-page-row">
      <div className="landing-page-header">
        <img
          src={Logo}
          alt="inquisitio"
          id="logo"
          width="160px"
          height="60px"
        />
        <nav>
          <a href="" className="nav-utils-basics">
            Home
          </a>
          <a href="#about" className="nav-utils-basics">
            About
          </a>
          <div className="dropdown">
              <span className="nav-utils" id="nav-more">More</span>
              <div class="dropdown-content">
                <a href="#values">Our values</a>
                <a href="#testimony">Testimony</a>
                <a href="#mobile">Mobile app</a>
              </div>
          </div>

          <button className="nav-utils" id="signin-modal-button" onClick={ () => setSignin(true)}>sign in</button>
          <SignInModal onClose={ () => setSignin(false)} show={signin}/>
          <div className="tooltip">
               <img src={sound} alt="sound" id="sound" className="nav-utils" />
                <small className="header-tooltip-text">sound effect</small>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
