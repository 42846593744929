import React from 'react';
import AdminNavbar from '../../components/AdminNavbar';
import DiseaseMain from '../../components/disease-restriction-main/DiseaseMain';
import './css/main.css';
import { useState, useEffect } from 'react';
import { axiosInstance } from '../../utils/axios.instance';

const DiseaseRestriction = () => {

    // loading
    const [loading, setLoading] = useState(false);
    const [disease, setDisease] = useState([]);
    const [searchApiData, setSearchApiData] = useState([]);

    const [adminProfile, setAdminProfile] = useState({});

    // load data
    useEffect(() => {
        setLoading(true);

        /**
         * Check if the user is logged in
         */
        axiosInstance.get('/admins/me/details')
            .then(res => {
                setLoading(false);
                setAdminProfile(res.data);
                axiosInstance.get('/maladies')
                    .then(res => {
                        setDisease(res.data.maladies);
                        console.log(res.data);
                        setLoading(false);
                    })
                    .catch(err => {
                        console.log(err);
                        setLoading(false);
                    });

            })
            .catch(error => {
                window.location.href = "/admin-auth";
            });
    }, []);

    return (
        <div className="row">
            <AdminNavbar />
            {
                loading ?
                    <div className="loading">
                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                    :
                    <DiseaseMain disease={disease} setDisease={setDisease} profile={adminProfile} />
            }
        </div>
    );
}

export default DiseaseRestriction;