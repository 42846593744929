import '../../components/doctor-homepage-main/doctorHomepageMain.css'
import diagno from '../../assets/diagno.png'
import patient from '../../assets/patient.png'
import calender from '../../assets/calender.png'

const DoctorHomepageMain = () => {
    return ( 
        <div className="doctor-col-9">
            <header className="doctor-header">
                <h3>Homepage</h3>
                <h5>Welcome, Dr Doe!</h5>
            <form action="">
                    <input type="search" placeholder="Search Something..." name="search" id="doctor-search-bar" />
            </form>
            </header>

            <section className="doctor-intro">
                <div className="doctor-intro-header">
                    <h4>Welcome back to your account</h4>
                    <h5>How to use ?</h5>
                    <p>Discover the different functionalities of your menu in the list down below.</p>
                </div>
                <div className="doctor-functionalities-cards">
                    <div className="doctor-functionality-patient">
                        <h6>Patient management</h6>
                        <img src={patient} alt="patient" />
                        <p>Save time by managing your patients from your patient management space.</p>
                    </div>
                    <div className="doctor-functionality-appointment">
                        <h6>Appointment management</h6>
                        <img src={calender} alt="calender" width="35px" />
                        <p>Keep track of your appointment from your appointment management space.</p>
                    </div>
                    <div className="doctor-functionality-diagnostics">
                        <h6>Diagnostics</h6>
                        <img src={diagno} alt="diagno" width="35px" />
                        <p>Run the different diagnostics from your diagnostics space.</p>
                    </div>
                </div>
            </section>
        </div>
     );
}
 
export default DoctorHomepageMain;