import '../../pages/admin-settings/css/adminSettings.css';
import AdminNavbar from '../../components/AdminNavbar';
import AdminSettingsMain from '../../components/admin-settings-main/AdminSettingsMain'
import { axiosInstance } from '../../utils/axios.instance';
import { useState, useEffect } from 'react';

const AdminSettings = () => {
    
    const [adminProfile, setAdminProfile] = useState({});
    const [loading, setLoading] = useState(false);

      // load data
      useEffect(() => {
        setLoading(true);

        /**
         * Check if the user is logged in
         */
        axiosInstance.get('/admins/me/details')
            .then(res => {
                setLoading(false);
                setAdminProfile(res.data);

            })
            .catch(error => {
                window.location.href = "/admin-auth";
            });
    }, []);

    return (  
        <div className="admin-settings-row">
            <AdminNavbar/>
            <AdminSettingsMain profile={adminProfile}/>
        </div>
    );
}
 
export default AdminSettings;