import './AddPatientModal.css'
import { useState } from 'react';
import { toast } from "react-toastify";
import { axiosInstance } from '../../../utils/axios.instance';

const AddPatientModal = props => {

    if(!props.show){
        return null;
    }


    return ( 
        <div className="add-modal">
        <div className="add-modal-content">
            <div className="add-modal-header">
                <h4 className="add-modal-title">Add Patient</h4>
                <button onClick={props.onClose}>x</button>
            </div>
            <div className="add-modal-body">
                <form action="" >
                <div className='form_sep_fir'>
                        <table className='form_tab_fir'>
                            <tr>
                                <td><label>Cin:</label></td>
                            </tr>
                            <tr>
                                <td><input type="text" placeholder="Q124415" className='f_mail' name=""/></td>
                            </tr>
                        </table>
                    </div>
                    <br />
                    <br/>
                    <div className='form_sep_sec'>
                        <table className='form_tab_sec'>
                            <tr>
                                <td><label>First name:</label></td>
                                <td><label>Last name:</label></td>
                            </tr>
                            <tr>
                                <td><input type="text" placeholder="First name" className='f_fname' name=""/></td>
                                <td><input type="text" placeholder="Last name" className='f_lname' name=""/></td>
                            </tr>
                        </table>
                    </div>
                    <br />
                    <div className="add-modal-footer">
                <button type="button" className="close-button" onClick={props.onClose}>close</button>
                <button type="submit" className="submit-button">Add Patient</button>
            </div>
                </form>
            </div>
            
        </div>
    </div>
     );
}
 
export default AddPatientModal;