import { useState } from 'react'
import '../assistant-management-main/AssistantMain.css'
import AddAssistantModal from '../modals/assistant-management/AddAssistantModal'
import ModifyAssistantModal from '../modals/assistant-management/ModifyAssistantModal'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../utils/axios.instance'


const AssistantMain = ({assistant,profile,setAssistant}) => {

    const [show, setShow] = useState(false) 
    const [modal, setModal] = useState(false);
    const [searchTerm,setSearchTermA] = useState("");


    return ( 
        <div class="col-9">
            <header>
                    <h3>Assistant Management</h3>
                    <h5>Welcome, {profile.firstName + " " + profile.lastName}</h5>
                    <form action="">
                        <input type="search" placeholder="Search Something..." name="search" id="search-bar" />
                    </form>
            </header>

            <section id="intro">
                <h6>Manage The Assisant's Accounts</h6>
                <p>The section bellow is dedicated to manage the assistants’ account.</p>
            </section>

            <div className="functionalities">
                <form action="">
                    <input type="search" placeholder="Search assistant" name="search-assistant" id="assistant-search-bar" onChange={(e)=>{setSearchTermA(e.target.value)}} />
                </form>
                <button type="button" id="assistant-add-modal" onClick={ () => setShow(true)}> + Add Assistant</button>
                <AddAssistantModal onClose={ (login,firstName,lastName,password) => {
                    setShow(false);
                    if(firstName && lastName && login && password){
                        setAssistant([...assistant,{id: "new",login,firstName,lastName,password}]);
                    }
                    }} show={show}/>
                <section id="pagination">
                <button type="button" id="previous-page">Previous</button>
                <button type="button" id="next-page">Next</button>
                </section>
                
            </div>

            <div className="assistant-display">
                <table>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>login</th>
                            <th>first name</th>
                            <th>last name</th>
                            <th>actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        assistant.filter(val=>{
                            if(searchTerm === ''){
                                return val;
                            }else if(
                                val.firstName.toLowerCase().includes(searchTerm.toLowerCase())
                            ){
                                return val;
                            }
                        }).map(assistant => {
                            return (
                                <tr>
                                    <td>{assistant.id}</td>
                                    <td>{assistant.email}</td>
                                    <td>{assistant.firstName}</td>
                                    <td>{assistant.lastName}</td>
                                    <td>
                                        
                                        <button id="assistant-modify-modal" onClick={() => setModal({
                                            ...modal,
                                            [assistant.id]: true
                                        })}>modify</button>
                                        <button id="assistant-delete-button" onClick={(e) => {
                                            axiosInstance.
                                                delete('/secretaire/' + assistant.id).
                                                then(res => {
                                                    setAssistant(assistant.filter(d => d.id !== assistant.id))
                                                    toast("Assistant deleted successfully", {
                                                        type: toast.TYPE.WARNING,
                                                        position: toast.POSITION.BOTTOM_CENTER
                                                    }
                                                    );
                                                })
                                        }}>delete</button>
                                        <ModifyAssistantModal id={assistant.id} onClose={() => setModal({
                                            ...modal,
                                            [assistant.id]: false
                                        })} show={modal[assistant.id]} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
           
        </div>
     );
}
 
export default AssistantMain;