import React from 'react'
import { useState } from 'react'
import './IaMain.css'
import { axiosInstance } from '../../utils/axios.instance'
import { toast } from 'react-toastify'
import ReactDOM from "react-dom";
import Progressbar from '../progress_bar/ProgressBar'

const IaMain = ({profile}) => {

    const [files, setFiles] = useState([]);
    const [show, setShow] = useState(false);
    const [urlImg, setUrlImg] = useState("");
    const [prediction, setPrediction] = useState([]);

    const smartIa = (e) => {
        e.preventDefault();

        for (let i = 0; i < e.target.files.length; i++) {
            const fileName = e.target.files[i].name;

            axiosInstance.get(
                '/admins/upload?file=' + fileName
            ).then(res => {
                console.log(res.data);

                const postUri = res.data.url;

                const formData = new FormData();


                for (const [key, value] of Object.entries(res.data.fields)) {
                    formData.append(key, value);
                }

                formData.append('file', e.target.files[i]);

                fetch(postUri, {
                    method: 'POST',
                    mode: 'cors',
                    body: formData
                }).then(async response => {
                    console.log("Uploaded at " + res.data.destination);
                    setFiles(files => [...files, res.data.destination]);
                    setUrlImg(res.data.destination);
                    setShow(true);

                    fetch('https://ocular-api.gameshakes.com/predict?url='+res.data.destination,{
                        mode:'cors',
                    }).then(async respIa =>{
                        if(respIa.ok){
                            const dataIa = await respIa.json();
                            console.log(dataIa);
                            setPrediction(dataIa.prediction);
                            setShow(true)
                        }
                    }).catch(err =>{
                        toast("Bad Image!!!", {
                            type: toast.TYPE.WARNING,
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    })
                }
                ).catch(err => {
                    console.log(err.message);
                }
                );
            })
        }

    }

    return ( 
        <div class="col-9">
            <header>
                    <h3>SMART IA Assistant</h3>
            </header>

            <div className='body_ia'>
                <form action="">
                    <input type="file" accept="image/*" className='f_up' name="" id="" onChange={smartIa}/>
                </form>

                <center>
                <div className='img_ia'>
                    {
                        show?<img src={urlImg} width="290px"/>:null
                    }
                </div>
                </center>

                <div className='inf_ia'>
                    {
                        show?prediction.map(pre => {
                            return(
                                <div className='lines_bet'>
                                    <h6>{pre[0]} {pre[1]}</h6>
                                    <Progressbar className="progress" bgcolor="#636FF3" progress={pre[1].replace(/[%normalabc12345#$*,%]/g,'')}  height={15} />
                                </div>
                            )
                        }):null
                    }
                </div>
            </div>

        </div>
     );
}
 
export default IaMain;