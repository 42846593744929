import '../../components/admin-settings-main/AdminSettingsMain.css'
import AdminPhoto from '../../assets/admin_photo.png';
import AddAdmin from '../../components/modals/add-admin/AddAdmin'
import { useState } from 'react'

const AdminSettingsMain = ({profile}) => {

    const [addAdmin, setaddAdmin] = useState(false)

    return ( 
        <div className="admin-settings-col-9">
            <header className="admin-settings-header">
                <div className="admin-settings-header-message">
                    <h3>Disease Restriction</h3>
                    <h5>Welcome, {profile.firstName + " " + profile.lastName} !</h5>
                </div>
                <button className="add-new-admin-button" onClick={() => setaddAdmin(true)}>+ Add a new admin</button>

            </header>
            <AddAdmin onClose={ () => setaddAdmin(false)} show={addAdmin}/>
            <div className="admin-info-display">
                <div className="admin-img">
                     <img src={AdminPhoto} alt="admin" />
                </div>
                <div className="admin-info">
                    <h4>WELCOME TO YOUR PERSONAL SETTINGS SECTION</h4>
                    <h5>John Doe</h5>
                    <p>Application administrator at inquisitio</p>
                </div>
              
            </div>

            <div className="admin-settings-credentials">
                <h4>Account credentials</h4>
                <form action="" className="admin-settings-credentials-form">
                    <div className="admin-email">
                        <label>Email</label>
                        <br />
                        <input type="email" placeholder="example@gmail.com" name="email"/>
                    </div>
                    <div className="admin-names">
                        <div className="admin-first-name">
                            <label>First Name</label>
                            <br />
                            <input type="text" placeholder="John" name="first-name"/>
                        </div>
                        <div className="admin-last-name">
                            <label>Last Name</label>
                            <br />
                            <input type="text" placeholder="Doe" name="last-name"/>
                        </div>
                    </div>
                    <div className="admin-passwords">
                        <div className="admin-old-password">
                            <label>Old Password</label>
                            <br />
                            <input type="password" placeholder="old password" name="old-password"/>
                        </div>
                        <div className="admin-new-password">
                            <label>New Password</label>
                            <br />
                            <input type="password" placeholder="new password" name="new-password"/>
                        </div>
                    </div>
                </form>
                <button type="submit" className="admin-credetianls-update-button">update</button>

            </div>
            


        </div>
     );
}
 
export default AdminSettingsMain;