import AdminNavbar from '../../components/AdminNavbar'
import AssistantMain from '../../components/assistant-management-main/AssistantMain';
import './css/main.css';
import { useState, useEffect } from 'react';
import { axiosInstance } from '../../utils/axios.instance';

const AssistantManagement = () => {

    const [loading, setLoading] = useState(false);
    const [assistant, setAssistant] = useState([]);
    const [adminProfile, setAdminProfile] = useState({});

    useEffect(() => {
        setLoading(true);
        axiosInstance.get('/admins/me/details')
            .then(res => {
                setLoading(false);
                setAdminProfile(res.data);
                axiosInstance.get('/secretaire')
                    .then(res => {
                        setAssistant(res.data);
                        console.log(res.data);
                        setLoading(false);
                    })
                    .catch(err => {
                        console.log(err);
                        setLoading(false);
                    });

            })
            .catch(error => {
                window.location.href = "/admin-auth";
            });
    }, []);
    

    return (
        <div className="row">
            <AdminNavbar />
            <div className="loading">
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
            <AssistantMain assistant={assistant} setAssistant={setAssistant} profile={adminProfile} />
        </div>
    );
}

export default AssistantManagement;