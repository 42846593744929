import AdminNavbar from '../../components/AdminNavbar'
import DoctorMain from '../../components/doctor-management-main/DoctorMain';
import './css/main.css';
import { useState, useEffect } from 'react';
import { axiosInstance } from '../../utils/axios.instance';

const DoctorManagement = () => {

    const [loading, setLoading] = useState(false);
    const [doctor, setDoctor] = useState([]);
    const [adminProfile, setAdminProfile] = useState({});

    useEffect(() => {
        setLoading(true);

        /**
         * Check if the user is logged in
         */
        axiosInstance.get('/admins/me/details')
            .then(res => {

                setLoading(false);
                setAdminProfile(res.data);

                axiosInstance.get('/medecins')
                    .then(res => {
                        setDoctor(res.data);
                        console.log(res.data);
                        setLoading(false);
                    })
                    .catch(err => {
                        console.log(err);
                        setLoading(false);
                    });

            })
            .catch(error => {
                window.location.href = "/admin-auth";
            });
    }, []);
    
    return (
        <div className="row">
            <AdminNavbar />
            <div className="loading">
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
            <DoctorMain doctor={doctor} setDoctor={setDoctor} profile={adminProfile}/>
        </div>
    );
}

export default DoctorManagement;