import { useEffect, useState } from 'react';
import { axiosInstance } from '../../utils/axios.instance';
import '../modals/ModifyDiseaseModal.css'

import {toast} from "react-toastify";


const ModifyDiseaseModal = modif => {
    const [nom, setNom] = useState("");
    const [desc, setDesc] = useState("");

    useEffect(() => {
        axiosInstance.get('/maladies/' + modif.id).then(res => {
            setNom(res.data.maladie.nom);
            setDesc(res.data.maladie.description);
        })
    }, [modif.id]);

    const handleSubmit = (e) => {
        e.preventDefault();

        axiosInstance
        .put('/maladies/' + modif.id, {
            id: modif.id,
            nom,
            description: desc
        })
        .then(res => {
            console.log("UPDATE---------------", res.data);

            toast("Updated maladie", {
                type: toast.TYPE.SUCCESS,
                position: toast.POSITION.BOTTOM_CENTER
            })

            modif.onClose({
                nom: res.data.nom,
                desc: res.data.description,
                id: res.data.id
            });
            
        })
    }

    if (!modif.show) {
        return null;
    }


    return (
        <div className="disease-modify-modal">
            <div className="disease-modify-modal-content">
                <div className="disease-modify-modal-header">
                    <h4 className="disease-modify-modal-title">Modify Disease</h4>
                    <button onClick={modif.onClose}>x</button>
                </div>
                <div className="disease-modify-modal-body">
                    <form action="" onSubmit={handleSubmit}>
                        <label>Disease name:</label><br />
                        <input type="text" placeholder="Enter the name of the disease" name="" value={nom} onChange={(e) => setNom(e.target.value)} />
                        <br /><br />
                        <label>Disease description:</label><br />
                        <input type="text" placeholder="Enter the description of the disease" name="" value={desc} onChange={(e) => setDesc(e.target.value)} />
                        <br /> 
                        <div className="disease-modify-modal-footer">
                            <button type="button" className="disease-close-button" onClick={modif.onClose}>close</button>
                            <button type="submit" className="disease-submit-button">update</button>
                        </div>
                    </form>
                </div>
               
            </div>
        </div>
    );
}

export default ModifyDiseaseModal;