import '../../components/admin-homepage-main/adminhomepagemain.css'
import React from 'react'
import Stats from '../../assets/stats.png'
import Users from '../../assets/users.png'
import Diseases from '../../assets/disease_res.png'
import AdminHomeIcon from '../../assets/admin_homepage.png'
import { axiosInstance } from '../../utils/axios.instance'

const AdminHomepageMain = ({profile}) => {

    return ( 
        <div className="admin-homepage-col-9">
             <header className="admin-homepage-header">
                <h3>Homepage</h3>
                <h5>Welcome, {profile.firstName + " " + profile.lastName} !</h5>
                <form action="">
                        <input type="search" placeholder="Search Something..." name="search" id="admin-search-bar" />
                </form>
            </header>

            <div className="admin-home-intro">
                <div className="admin-home-intro-message">
                    <h4>Welcome In Your <span>Admin</span> Account</h4>
                    <h6>The purpose of this platform is to enable you to <span>manage</span> the different app users as well as <span>restricting</span> the ocular diseases.</h6>
                    <div className="admin-home-intro-stats">
                        <div className="admin-home-intro-first-stat">
                            <img src={Stats} alt="stats" />
                            <p>Stats <br /> Consulting</p>
                        </div>
                        <div className="admin-home-intro-second-stat">
                            <img src={Users} alt="Users" />
                            <p>User Management</p>
                        </div>
                        <div className="admin-home-intro-third-stat">
                            <img src={Diseases} alt="Diseases" />
                            <p>Disease Restriction</p>
                        </div>
                    </div>
                </div>
                <div className="admin-home-intro-icon">
                    <img src={AdminHomeIcon} alt="admin-icon" />
                </div>
            </div>
        </div>
     );
}
 
export default AdminHomepageMain;