import '../../components/modals/ImagePreview.css'
import Slider from 'react-slick';
import {FaArrowRight, FaArrowLeft} from "react-icons/fa"
import { useState, useEffect } from 'react';
import { axiosInstance } from '../../utils/axios.instance';
import Carousel from 'react-bootstrap/Carousel'





const ImagePreview = imagePreview => {
    const [idStade,setIdStade] = useState('');
    const [images,setImages] = useState([]);
    
    const NextArrow = ({onClick}) => {
        return(
            <div className="arrow next" onClick={onClick}>
            </div>
        )
    }

    const PrevArrow = ({onClick}) => {
        return(
            <div className="arrow prev" onClick={onClick}>
            </div>
        )
    };

    const [ImageIndex, setImageIndex] = useState(0)

    const settings = {
        dots: true,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
        infinite: true,
        lazyLoad: true,
        speed: 300,
        SlidesToShow: 3,
        centerMode: true,
        centerPadding:0,
        beforeChange: (current, next) => setImageIndex(next)
    };


    useEffect(() => {
        axiosInstance.get('/images/preview/'+imagePreview.id)
            .then(res => {
                console.log(res.data);
                setImages(res.data)
            })
            .catch(err => {
                console.log(err);
            });

    })
    if(!imagePreview.show){   
        return null;
    }
          


    return ( 
        <div className="image-preview-modal">
            <div className="image-preview-content">
                <div className="image-preview-header">
                 <button id="image-preview-close-button" onClick={imagePreview.onClose} >x</button>
                </div>
                <div className="">
              
                    {images.url}
                    {imagePreview.ImagePreview}
                  
                    <Slider {...settings}>
                        {images.map((img,idx ) => (
                            <div  className={idx == ImageIndex ? "slide activeSlide" : "slide"  }>
                                <img src={img.url}  style={{width: '370px', height: '370px'}} />

                            </div>
                        ))}
                    </Slider>
                    {imagePreview.id}

                </div>
            </div>
        </div>
     );
}
 
export default ImagePreview;