import '../../components/landing-page-footer/footer.css';
import FooterLogo from "../../assets/footer_logo.JPG";

const Footer = () => {
    return ( 

        <div className="footer">


            <section>
                <h6>Features</h6>
                <ul>
                    <li><a href="">AI Support</a></li>
                    <li><a href="">Staff Management</a></li>
                    <li><a href="">Growing Community</a></li>
                    <li><a href="">Guarenteed Security</a></li>
                    <li><a href="">Custom support</a></li>
                </ul>
            </section>
            
            <section>
                <h6>Use case</h6>
                <ul>
                    <li><a href="">Initiation Documentation</a></li>
                    <li><a href="">Product Walkthroughs</a></li>
                    <li><a href="">Explainer videos</a></li>
                    <li><a href="">Ethics Guidelines</a></li>
                    
                </ul>
            </section>

            <section>
            <h6>Company</h6>
                <ul>
                    <li><a href="">About us</a></li>
                    <li><a href="">Privacy policy</a></li>
                    <li><a href="">Terms of service</a></li>
                    <li><a href="">Product updates</a></li>
                    <li><a href="">Help center</a></li>
                    <li><a href="">Contact security</a></li>
                </ul>
            </section>

            <aside>
                <img src={FooterLogo} alt="logo" width="220" height="50"/>
                <caption>© 2021 - 2022 inquisitio inc <br/> All Rights Reserved</caption>
            </aside>
                

        </div>

     );
}
 
export default Footer;