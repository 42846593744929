import React from 'react'
import { useState } from 'react'
import './ExpertMain.css'
import AddDoctorModal from '../modals/doctor-management/AddDoctorModal'
import ModifyDoctorModal from '../modals/doctor-management/ModifyDoctorModal'
import { axiosInstance } from '../../utils/axios.instance'
import { toast } from 'react-toastify'
import AddExpertModal from '../modals/expert-management/AddExpertModal'

const ExpertMain = ({expert,profile,setExpert}) => {

    const [show, setShow] = useState(false) 
    const [modal, setModal] = useState(false);
    const [searchTerm,setSearchTerm] = useState("");

    return ( 
        <div class="col-9">
            <header>
                    <h3>Expert Management</h3>
                    <h5>Welcome, {profile.firstName + " " + profile.lastName}!!</h5>
                    <form action="">
                        <input type="search" placeholder="Search Something..." name="search" id="search-bar" />
                    </form>
            </header>

            <section id="intro">
                <h6 style={{}}>Manage the Expert's Account</h6>
                <p>The section bellow is dedicated to manage the expert's account.</p>
            </section>

            <div className="functionalities">
                <form action="">
                    <input type="search" placeholder="Search doctor" name="search-doctor" id="doctor-search-bar" onChange={(e)=>{setSearchTerm(e.target.value)}} />
                </form>
                <button type="button" id="doctor-add-modal" onClick={ () => setShow(true)}> + Add Expert</button>
                <AddExpertModal onClose={ ({login,password}) => {
                    setShow(false);
                    if( login&& password){
                        setExpert([...expert,{id: "new",login,password}]);
                    }
                    }} show={show}/>
                <section id="pagination">
                <button type="button" id="previous-page">Previous</button>
                <button type="button" id="next-page">Next</button>
                </section>
                
            </div>
            <div className="doctor-display">
                <table>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>login</th>
                            <th>password</th>
                            <th>actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        expert.filter(val=>{
                            if(searchTerm === ''){
                                return val;
                            }else if(
                                val.email.toLowerCase().includes(searchTerm.toLowerCase())
                            ){
                                return val;
                            }
                        }).map(dc => {
                            return (
                                <tr>
                                    <td>{dc.id}</td>
                                    <td>{dc.email}</td>
                                    <td>{dc.password}</td>
                                    <td>
                                        
                                        <button id="doctor-modify-modal" onClick={() => setModal({
                                            ...modal,
                                            [dc.id]: true
                                        })}>modify</button>
                                        <button id="doctor-delete-button" onClick={(e) => {
                                            axiosInstance.
                                                delete('/experts/' + dc.id).
                                                then(res => {
                                                    setExpert(expert.filter(d => d.id !== dc.id))

                                                    toast("Expert deleted successfully", {
                                                        type: toast.TYPE.WARNING,
                                                        position: toast.POSITION.BOTTOM_CENTER
                                                    }
                                                    );
                                                })
                                        }}>delete</button>
                                        <ModifyDoctorModal  id={dc.id} onClose={() => setModal({
                                            ...modal,
                                            [dc.id]: false
                                        })} show={modal[dc.id]} />
                                    </td>
                                </tr>
                            )
                        })
                    }                      
                    </tbody>
                </table>
            </div>
           
        </div>
     );
}
 
export default ExpertMain;