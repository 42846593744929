import '../../components/admin-dashboard-main/admindashboardmain.css'
import TotalUsers from '../../assets/total_users.png'
import TotalDoctors from '../../assets/total_doctors.png'
import TotalAssistants from '../../assets/total_assistants.png'
import UsersUpdates from '../../assets/users_updates.png'
import DiseaseUpdates from '../../assets/disease_updates.png'

const AdminDashboardMain = ({profile}) => {
    return ( 
        <div className="admin-dashboard-col-9">
            <header className="admin-dashboard-header">
                <h3>Dashboard</h3>
                <h5>Welcome,{profile.firstName + " " + profile.lastName} !</h5>
                <form action="">
                        <input type="search" placeholder="Search Something..." name="search" id="admin-search-bar" />
                </form>
            </header>


            <div className="admin-dashboard-intro">
                <div className="dashboard-date">
                <h4>Today</h4>
                <p>Monday, 22 Apr</p>
                <small>05:45 PM</small>
                </div>
                <div className="admin-dashboard-intro-message">
                    <h4>ADMIN DASHBOARD</h4>
                    <h6>Inquisitio offers you the abality to keep track of information at any time given.</h6>
                    <p>Scroll down to discover the different stats and information about your app.</p>
                </div>
            </div>
            
            <div className="dashboard-heading">
                <h4>My Dashboard</h4>
                    <div className="dashboard-cards">
                        <div className="dashboard-first-card">
                            <img src={TotalUsers} alt="total-users" />
                            <div className="first-card-stats">
                                <h6>Total Users</h6>
                                <p>225</p>
                            </div>
                        </div>
                        <div className="dashboard-second-card">
                            <img src={TotalDoctors} alt="total-doctors" />
                            <div className="second-card-stats">
                                <h6>Total Doctors</h6>
                                <p>115</p>
                            </div>
                        </div>
                        <div className="dashboard-third-card">
                            <img src={TotalAssistants} alt="total-assistants" />
                            <div className="third-card-stats">
                                <h6>Total Assistants</h6>
                                <p>110</p>
                            </div>
                            
                        </div>
                    </div>
            </div>

            <div className="recent-updates-stats">
                <h4>Recent Updates</h4>
                    <div className="recent-updates-stats-cards">
                        <div className="recent-updates-first">
                            <img src={UsersUpdates} alt="users-updates" />
                            <h5>59+</h5>
                            <h6>Active Users</h6>
                        </div>
                        <div className="recent-updates-second">
                            <img src={DiseaseUpdates} alt="disease-updates" />
                            <h5>Managed Ocular Disease</h5>
                            <h6>Cataract</h6>
                        </div>
                 </div>
               
            </div>

        </div>
     );
}
 
export default AdminDashboardMain;