import '../components/adminNavbar.css' ;
import logo from '../assets/logo.png';
import admin_home_icon from '../assets/admin_home_icon.png';
import admin_navbar_home from '../assets/admin_navbar_home.png';
import admin_navbar_dashboard from '../assets/admin_navbar_dashboard.png';
import admin_navbar_doctor from '../assets/admin_navbar_doctor.png';
import admin_navbar_assistant from '../assets/admin_navbar_assistant.png';
import admin_navbar_disease from '../assets/admin_navbar_disease.png';
import settings_icon from '../assets/settings_icon.png';
import signout_icon from '../assets/signout_icon.png';
import { axiosInstance } from '../utils/axios.instance';
import aiAssistant from '../../src/assets/ai_assistant.png'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom';

const AdminNavbar = () => {
    return ( 
        <div className="col-3" >
           <img src={logo} alt="inquisitio-logo" id="logo" width="160" height="60"></img>

            <ul class="admin-menu">
                <li>
                    <a href="/admin-homepage">
                        <img src={admin_navbar_home} alt="home-icon"/>
                        <small>Home</small>
                    </a>
                </li>
                <li>
                <a href="/admin-dashboard">
                        <img src={admin_navbar_dashboard} alt="dashboard-icon"/>
                        <small>Dashboard</small>
                    </a>
                </li>
                <li>
                <a href="/doctor-management">
                        <img src={admin_navbar_doctor} alt="doctor-icon"/>
                        <small>Doctor Management</small>
                    </a>
                </li>
                <li>
                <a href="assistant-management">
                        <img src={admin_navbar_assistant} alt="assistant-icon" width="23px"  height="23px"/>
                        <small>Assistant Management</small>
                    </a>
                </li>
                <li>
                <a href="/disease-restriction">
                        <img src={admin_navbar_disease} alt="disease-icon"  width="25px"  height="23px"/>
                        <small>Disease Restriction</small>
                    </a>
                </li>
                <li>
                <a href="/ia-assistant">
                        <img src={aiAssistant} alt="ai-icon"  width="25px"  height="23px"/>
                        <small>Ai assistant</small>
                    </a>
                </li>
            </ul>

            <hr />

            <ul className="admin-settings">
                <li>
                    <a href="admin-settings">
                        <img src={settings_icon} alt="settings" width="25px" height="25px"/>
                        <small>settings</small>
                    </a>
                </li>
                <Link to="/admin-auth" onClick={(e) => {
                        axiosInstance.
                            get('/shared/logout').
                            then(res => {
                                toast("Logout successfully", {
                                    type: toast.TYPE.INFO,
                                    position: toast.POSITION.BOTTOM_CENTER
                                }
                                );
                                window.location.href = "/admin-auth";
                            })
                    }}>
                <li>
                <a href="">
                        <img src={signout_icon} alt="sign-out" width="20px" height="20px" />
                        <small>Sign out</small>
                    </a>
                </li>
                </Link>
            </ul>

            <img src={admin_home_icon} id="admin-logo" alt="home" width="50px" height="50px"/>
            <caption>Admin</caption>
        </div>

     );
}
 
export default AdminNavbar;