import '../../components/landing-page-about/about.css'

const About = () => {
    return (  
        <div className="about-row">
            <div className="about-content" id="about">
                 <small>-about-</small>
                 <h3>THE FUTURE OF <br /> OPHTALMOLOGY</h3>
                 <iframe src="https://share.synthesia.io/embeds/videos/3ebbbab5-9700-4a1b-a429-dbf924c2102a" loading="lazy" title="Synthesia video player - Your AI video" allow="encrypted-media; fullscreen;" width="1000px" height="450px" style={{ overflow : "hidden" }}></iframe>
                 <p>“At inquisitio, our mission is to deliver a product that is compatible with the <br /> values that define us. We thrive to satissfy our customers by proposing the <span>most</span> <br /> <span>reliable</span> tools for staff management and an <span>incomparable</span>  full AI support for <br /> diagnosis.”</p>
                 <button>Discover more</button>
            </div>
        </div>
    );
}
 
export default About;