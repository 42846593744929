import React from 'react'
import { useState } from 'react'
import './AssistantHome.css'
import AddDoctorModal from '../modals/doctor-management/AddDoctorModal'
import ModifyDoctorModal from '../modals/doctor-management/ModifyDoctorModal'
import { axiosInstance } from '../../utils/axios.instance'
import { toast } from 'react-toastify'
import patient from '../../assets/patient.png'
import calendar from '../../assets/calender.png'

const AssistantHomeComp = () => {

    const [show, setShow] = useState(false) 
    const [modal, setModal] = useState(false);

    return ( 
        <div class="assistant-col-9">
            <header>
                    <h3>Assistant Home</h3>
                    <h5>Welcome!</h5>
                    <form action="">
                        <input type="search" placeholder="Search Something..." name="search" id="search-bar" />
                    </form>
            </header>

              <section className="assistant-intro">
                <div className="assistant-intro-header">
                    <h4>Welcome back to your account</h4>
                    <h5>How to use ?</h5>
                    <p>Discover the different functionalities of your menu in the list down below.</p>
                </div>
                <div className="assistant-functionalities-cards">
                    <div className="assistant-functionality-patient">
                        <h6>Patient management</h6>
                        <img src={patient} alt="patient" />
                        <p>Save time by managing your patients from your patient management space.</p>
                    </div>
                    <div className="assistant-functionality-appointment">
                        <h6>Appointment management</h6>
                        <img src={calendar} alt="calender" width="35px" />
                        <p>Keep track of your appointment from your appointment management space.</p>
                    </div>
                </div>
            </section>

           
           
        </div>
     );
}
 
export default AssistantHomeComp;