import './AddDoctorModal.css'
import { useState } from 'react';
import { toast } from "react-toastify";
import { axiosInstance } from '../../../utils/axios.instance';

const AddDoctorModal = props => {

    const [login, setLogin] = useState("");
    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [password, setPassword] = useState("");
    const [specialite, setSpecialite] = useState("");

    const addDoctors = (e) => {
        e.preventDefault();
        axiosInstance.post('/medecins', {
            email : login,
                firstName : firstName,
                lastName : lastName,
                password : password,
                specialite : specialite
          })
          .then(res => {
            props.onClose({ login,firstName,lastName,password,specialite });

            toast("Successfully added doctor", {
                type: toast.TYPE.SUCCESS,
                position: toast.POSITION.BOTTOM_CENTER
            });
        });
        
    }

    if(!props.show){
        return null;
    }


    return ( 
        <div className="add-modal">
        <div className="add-modal-content">
            <div className="add-modal-header">
                <h4 className="add-modal-title">Add Doctor</h4>
                <button onClick={props.onClose}>x</button>
            </div>
            <div className="add-modal-body">
                <form action="" onSubmit={addDoctors}>
                <div className='form_sep_fir'>
                        <table className='form_tab_fir'>
                            <tr>
                                <td><label>Login:</label></td>
                            </tr>
                            <tr>
                                <td><input type="text" placeholder="example@email.com" className='f_mail' value={login} onChange={(e) => setLogin(e.target.value)} name=""/></td>
                            </tr>
                        </table>
                    </div>
                    <br />
                    <div className='form_sep'>
                        <table className='form_tab'>
                            <tr>
                                <td><label>Password:</label></td>
                                <td><label>Confirm password:</label></td>
                            </tr>
                            <tr>
                                <td><input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} name=""/></td>
                                <td><input type="password" placeholder="Confirm password" name=""/></td>
                            </tr>
                        </table>
                    </div>
                    <br/>
                    <div className='form_sep_sec'>
                        <table className='form_tab_sec'>
                            <tr>
                                <td><label>First name:</label></td>
                                <td><label>Last name:</label></td>
                                <td><label>Speciality:</label></td>
                            </tr>
                            <tr>
                                <td><input type="text" placeholder="First name" value={firstName} onChange={(e) => setfirstName(e.target.value)} className='f_fname' name=""/></td>
                                <td><input type="text" placeholder="Last name" value={lastName} className='f_lname' onChange={(e) => setlastName(e.target.value)} name=""/></td>
                                <td><input type="text" placeholder="Speciality" value={specialite} className='f_spec' onChange={(e) => setSpecialite(e.target.value)} name=""/></td>
                            </tr>
                        </table>
                    </div>
                    <br />
                    <div className="add-modal-footer">
                <button type="button" className="close-button" onClick={props.onClose}>close</button>
                <button type="submit" className="submit-button">Add Doctor</button>
            </div>
                </form>
            </div>
            
        </div>
    </div>
     );
}
 
export default AddDoctorModal;