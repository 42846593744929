import '../../components/landing-page-contact/contact.css'
import contact from '../../assets/contact.png'
import phone from '../../assets/phone.png'
import mail from '../../assets/mail.png'
import location from '../../assets/location.png'
import Facebook from '../../assets/facebook.png'
import Linkedin from '../../assets/LinkedIn.png'
import Twitter from '../../assets/Twitter.png'

const Contact = () => {
    return (  
      <div className="contact-row">
          <div className="contact-section">
             <h4>Get In Touch</h4>
              <p>Send us a message if you have any suggestion or concerns. Or find us on our social media platforms.</p>
              <div className="contact-form">
                  <form action="">
                    <input type="text" placeholder="Full name" /> <br />
                    <input type="email"  placeholder="Email" /> <br />
                    <input type="text" placeholder="Message" /> <br />
                  </form>
                  <button type="submit">submit</button>
              </div>
             
          </div>
         <div className="contact-social">
            <img src={contact} alt="contact" width="400" />
            <ul id="contact-info">
              <li>
                <img src={location} alt="location" />
                <small>Inquisitio headquarters, San Fransicsco CA.</small>
              </li>
              <li>
                <img src={phone} alt="phone" />
                <small>+ 1-212-XXX-XXXX </small>
              </li>
              <li>
                <img src={mail} alt="mail" />
                <small>help@inquisitio.pro</small>
              </li>
            </ul>

            <ul id="social-media">

              <li>
                <a href="">
                  <img src={Linkedin} alt="Linkedin" />
                </a>
              </li>

              <li>
                <a href="">
                  <img src={Facebook} alt="Facebook" />
                </a>
              </li>

              <li>
                <a href="">
                <img src={Twitter} alt="Twitter" id="twitter"/>
                </a>
              </li>

            </ul>
         </div>
      </div>
    );
}
 
export default Contact;