import '../../components/landing-page-heading/heading.css'

const Heading = () => {
    return ( 
        <div className="heading-row">
            <h2> <span>Easiest</span> And <span>Fastest</span> Way To <br /> Make Your Appointment <span>Now</span></h2>
            <h4>Sign in if you already have an account or contact the tech support to ceate a new account.</h4>
        </div>
     );
}
 
export default Heading;