import logo from '../../assets/logo.png';
import '../../pages/admin-auth/admin_auth.css';

import { useState } from 'react';
import { axiosInstance } from '../../utils/axios.instance';
import { toast } from 'react-toastify';

const AdminAuth = () => {

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");



    const handleSubmit = (e) => {
        e.preventDefault();

        axiosInstance.post(
            '/admins/login',
            {
                login,
                password
            }
        ).then(res => {
            toast("Successfully logged in", {
                type: toast.TYPE.SUCCESS,
                position: toast.POSITION.BOTTOM_CENTER
            });

            setTimeout(() => {
                window.location.href = "/admin-homepage";
            }, 1000);
        })
            .catch(error => {
                toast("Invalid credentials", {
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_CENTER
                });
                setLogin("");
                setPassword("");
            })
    }

    return (
        <div className="main">
            <section className="left-side">
                <header>
                    <img src={logo} alt="logo-inquisitio" />
                    <a href="/"><button>GO BACK TO HOMEPAGE</button></a>
                    
                </header>

                <h2>Welcome to the <span>Inquisitio</span> Admin portal.</h2>
                <h4>This page is restricted.</h4>

                <form action="" id="auth-form" onSubmit={handleSubmit}>
                    <div className="auth-inputs">
                        <input type="text" placeholder="username" name="username" id="username-input" value={login} onChange={(e) => setLogin(e.target.value)} />
                    </div>
                    <div className="auth-inputs">
                        <input type="password" placeholder="password" name="password" id="password-input" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div class="remember-me">
                        <input type="checkbox" id="remember-me-checkbox" />
                        <span>Remember me</span>
                    </div>

                    <button type="submit" id="login-button"> sign in</button>
                </form>
            </section>
            <section className="right-side">
            </section>
        </div>
    );
}

export default AdminAuth;