import { useEffect, useState } from 'react'
import '../../components/modals/PreviewStagesModal.css'
import PreviewImage from '../../assets/preview.png'
import ImagePreview from '../../components/modals/ImagePreview.jsx'
import { axiosInstance } from '../../utils/axios.instance'
import { toast } from "react-toastify";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' 

const PreviewStagesModal = stages => {

    const [disease_img, setImg] = useState(false);
    const [files, setFiles] = useState([]);
    const [nom, setNom] = useState("");
    const [description, setDescription] = useState("");
    const [maladieId, setMaladieId] = useState("");
    const [stadeId, setStadeId] = useState("");
    const [stadeArr,setStadeArr] =useState([]);
    const [ModalPrev,setModalPrev] = useState({});

    const handleImage = (e) => {
        e.preventDefault();

        for (let i = 0; i < e.target.files.length; i++) {
            const fileName = e.target.files[i].name;
        
            axiosInstance.get(
                '/admins/upload?file=' + fileName
            ).then(res => {
                console.log(res.data);

                const postUri = res.data.url;

                const formData = new FormData();


                for (const [key, value] of Object.entries(res.data.fields)) {
                    formData.append(key, value);
                }

                formData.append('file', e.target.files[i]);

                fetch(postUri, {
                    method: 'POST',
                    mode: 'cors',
                    body: formData
                }).then(async response => {
                    console.log("Uploaded at " + res.data.destination);
                    setFiles(files => [...files, res.data.destination]);
                    
                }
                ).catch(err => {
                    console.log(err.message);
                }
                );
            })
        }

    }

    const handleS = (e) => {
        e.preventDefault();
        axiosInstance.post('/stades', {
            nom : nom,
            description : description,
            maladieId : stages.id
        })
        .then(res => {
            axiosInstance.post('/images/add', {
                urls : files,
                stadeId : res.data.id
            })
            .then(res => {
                setStadeId(res.data.id)
                toast("Successfully added stade with pictures", {
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }).catch(err =>{
                console.log(err);
                console.log(err.message);
            });
        }).catch(err =>{
            console.log(err);
            console.log(err.message);
        });
    }

    useEffect(() => {
        axiosInstance.get('/stades/maladie/' + stages.id)
            .then(res => {
                console.log(res.data);
                setStadeArr(res.data);
            })
    }, []);

    if (!stages.show) {
        return null;
    }

    return (
        <div className="preview-disease-stages-modal">
            <div className="preview-disease-stages-modal-content">
                <div className="preview-disease-stages-modal-header">
                    <h4 className="preview-disease-stages-modal-title">Disease stages</h4>
                    <button id="close-button" onClick={stages.onClose} >x</button>
                </div>
                <div className="preview-disease-stages-modal-body">
                    <div className="preview-disease-stages-modal-message-section">
                        <p className="preview-disease-stages-modal-message">Add the different stages of this disease in the form below. </p>
                    </div>
                    <form action="" onSubmit={handleS}>
                        <div className="preview-disease-stages-modal-inputs">
                            <input type="text" placeholder="name" name="name" id="name" className="add-fields" value={nom} onChange={(e) => setNom(e.target.value)} />
                        </div>
                        <div className="preview-disease-stages-modal-inputs">
                            <input type="text" placeholder="description" name="description" id="description" className="add-fields" value={description} onChange={(e) => setDescription(e.target.value)} />
                        </div>
                        <div className="preview-disease-stages-modal-inputs">
                            <input type="file" name="disease-img" id="disease-img" accept="image/*" multiple className="add-fields" onChange={handleImage} />
                            <label for="upload">Choose image</label>
                        </div>
                        <button type="submit" id="submit-disease-stage">Submit</button>

                    </form>
                    <div className="disease-stages-displayer">

                        <button className="disease-stages-pagination">Previous</button>
                        <button className="disease-stages-pagination">Next</button>

                        <table className="disease-stages-displayer-table">
                            <thead className="disease-stages-displayer-thead">
                                <tr>
                                    <th className="disease-stages-displayer-th">id</th>
                                    <th className="disease-stages-displayer-th">name</th>
                                    <th className="disease-stages-displayer-th">description</th>
                                    <th className="disease-stages-displayer-th">Images</th>
                                    <th className="disease-stages-displayer-th">actions</th>
                                </tr>
                            </thead>
                            <tbody className="disease-stages-displayer-tbody">
                           
                                {
                                    stadeArr.map((st) => {
                                        return(
                                        <tr>
                                            <td>{st.id}</td>
                                            <td>{st.description}</td>
                                            <td>{st.nom}</td>
                                            <td className="disease-stages-displayer-td">
                                                <div className="tooltip">
                                                    <img src={PreviewImage} alt="Preview" onClick={() => setImg(true)} />
                                                    <span className="tooltiptext">preview image</span>
                                                    <ImagePreview  id={st.id} onClose={({description,nom}) => {
                                                        setModalPrev({
                                                            ...ModalPrev,
                                                            [st.id] : false
                                                        })
                                                    }} show={disease_img} />
                                                </div>
                                            </td>
                                            <button id="disease-delete-button" onClick={(e) => {
                                                axiosInstance.
                                                    delete('/stades/' + st.id).
                                                    then(res => {
                                                        toast("Stade deleted successfully", {
                                                            type: toast.TYPE.WARNING,
                                                            position: toast.POSITION.BOTTOM_CENTER
                                                        }
                                                        );
                                                    })
                                            }}>delete</button>
                                        </tr>
                                        )

                                    })
                                }
                                
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="disease-preview-disease-stages-modal-footer">
                    <button type="button" className="preview-close-button" onClick={stages.onClose}>close</button>
                </div>
            </div>
        </div>
    );
}

export default PreviewStagesModal;