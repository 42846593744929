import React from 'react'
import { useState } from 'react'
import './PatientMain.css'
import AddDoctorModal from '../modals/doctor-management/AddDoctorModal'
import ModifyDoctorModal from '../modals/doctor-management/ModifyDoctorModal'
import { axiosInstance } from '../../utils/axios.instance'
import { toast } from 'react-toastify'
import AddPatientModal from '../modals/patient-management/AddPatientModal'

const PatientMain = ({}) => {

    const [show, setShow] = useState(false) 
    const [modal, setModal] = useState(false);

    return ( 
        <div class="col-9">
            <header>
                    <h3>Patient Management</h3>
                    <h5>Welcome, !</h5>
                    <form action="">
                        <input type="search" placeholder="Search Something..." name="search" id="search-bar" />
                    </form>
            </header>

            <section id="intro">
                <h6>Manage the Patient's Account</h6>
                <p>The section bellow is dedicated to manage the patient’s account.</p>
            </section>

            <div className="functionalities">
                <form action="">
                    <input type="search" placeholder="Search doctor" name="search-doctor" id="doctor-search-bar" />
                </form>
                <button type="button" id="doctor-add-modal" onClick={ () => setShow(true)}> + Add Patient</button>
                <AddPatientModal onClose={ ({}) => {
                    setShow(false);
                    
                    }} show={show}/>
                <section id="pagination">
                <button type="button" id="previous-page">Previous</button>
                <button type="button" id="next-page">Next</button>
                </section>
                
            </div>

            <div className="doctor-display">
                <table>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>first name</th>
                            <th>last name</th>
                            <th>cin</th>
                            <th>actions</th>
                        </tr>
                    </thead>
                    <tbody>
                                          
                    </tbody>
                </table>
            </div>
           
        </div>
     );
}
 
export default PatientMain;