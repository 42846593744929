import React from 'react';
import './css/main.css';
import Header from '../../components/landing-page-header/Header';
import Heading from '../../components/landing-page-heading/Heading';
import About from '../../components/landing-page-about/About';
import Values from '../../components/landing-page-values/Values';
import Testimony from '../../components/landing-page-testimony/Testimony';
import Mobile from '../../components/landing-page-mobile-app/Mobile';
import Contact from '../../components/landing-page-contact/Contact'
import Footer from '../../components/landing-page-footer/Footer'
import { useState, useEffect } from 'react';


const LandingPage = () => {
    return ( 
        <div className="container">
            <Header/>
            <Heading/>
            <About/>
            <Values/>
            <Testimony/>
            <Mobile/>
            <Contact/>
            <Footer/>
        </div>
     );
}
 
export default LandingPage;