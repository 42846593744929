import '../../pages/doctor-settings/css/doctorsettings.css'
import DoctorNavbar from '../../components/doctor-navbar/DoctorNavbar'
import DoctorSettingsMain from '../../components/doctor-settings-main/DoctorSettingsMain'

const DoctorSettings = () => {
    return ( 
        <div className="doctor-settings-row">
            <DoctorNavbar/>
            <DoctorSettingsMain/>
        </div>
     );
}
 
export default DoctorSettings;