import '../../components/landing-page-mobile-app/mobile.css'
import google from '../../assets/google_play.png'

const Mobile = () => {
    return ( 
        
    <div className='mobile-row' id="mobile">
        <aside>
           <h4>Download our mobile app</h4> 
           <a href="">
               <img src={google} alt="google" />
           </a>
        </aside>
        
    </div> 
    
    );
}
 
export default Mobile;