import AdminNavbar from '../../components/AdminNavbar'
import DoctorMain from '../../components/doctor-management-main/DoctorMain';
import './css/main.css';
import { useState, useEffect } from 'react';
import { axiosInstance } from '../../utils/axios.instance';
import IaMain from '../../components/ia-assistant-main/IaMain';

const DoctorManagement = () => {

    const [loading, setLoading] = useState(false);
    const [adminProfile, setAdminProfile] = useState({});

    useEffect(() => {
        setLoading(true);
        axiosInstance.get('/admins/me/details')
            .then(res => {
                setLoading(false);
                setAdminProfile(res.data);
            })
            .catch(error => {
                window.location.href = "/admin-auth";
            });
    }, []);
    
    return (
        <div className="row">
            <AdminNavbar />
            <div className="loading">
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
            <IaMain profile={adminProfile}/>
        </div>

        
    );
}

export default DoctorManagement;