import { useState } from 'react'
import React from 'react'
import '../disease-restriction-main/DiseaseMain.css'
import AddDiseaseModal from '../../components/modals/AddDiseaseModal'
import ModifyDiseaseModal from '../../components/modals/ModifyDiseaseModal'
import PreviewStagesModal from '../../components/modals/PreviewStagesModal'
import { axiosInstance } from '../../utils/axios.instance'

import { toast } from 'react-toastify'

const DiseaseMain = ({ disease, profile, setDisease }) => {

    const [show, setShow] = useState(false)
    const [modalstate, setModalState] = useState({});
    const [stage, setStage] = useState(false);
    const [value,setValue] = useState('');
    const [searchTerm,setSearchTerm] = useState("");

    return (
        <div class="col-9">
            <header>
                <h3>Disease Restriction</h3>
                <h5>Welcome, {profile.firstName + " " + profile.lastName}!</h5>
                <form action="">
                    <input type="search" placeholder="Search Something..." name="search" id="search-bar" />
                </form>
            </header>

            <section id="intro">
                <h6>Restrict the ocular diseases</h6>
                <p>The section bellow is dedicated to manage and restricting the different ocular diseases’ account.</p>
            </section>

            <div className="disease-functionalities">
                <form action="">
                    <input type="search" placeholder="Search disease" name="search-disease" id="disease-search-bar" onChange={(e)=>{setSearchTerm(e.target.value)}} />
                </form>
                <button type="button" id="disease-add-modal" onClick={() => setShow(true)}> + Add Disease</button>
                <AddDiseaseModal onClose={({ nom, desc, id }) => {
                    setShow(false);
                    if (nom && desc) {
                        setDisease([...disease, { id, nom, description: desc }]);
                    }
                }} show={show} />
                <section id="disease-pagination">
                    <button type="button" id="previous-page">Previous</button>
                    <button type="button" id="next-page">Next</button>
                </section>

            </div>

            <div className="disease-display">
                <table className="disease-display-table">
                    <thead className="disease-display-thead">
                        <tr>
                            <th className="disease-display-th">id</th>
                            <th className="disease-display-th">name</th>
                            <th className="disease-display-th">description</th>
                            <th className="disease-display-th">actions</th>
                        </tr>
                    </thead>
                    <tbody className="disease-display-tbody">
                        {
                            disease.filter(val=>{
                                if(searchTerm === ''){
                                    return val;
                                }else if(
                                    val.nom.toLowerCase().includes(searchTerm.toLowerCase())
                                ){
                                    return val;
                                }
                            }).map(ds => {
                                return (
                                    <tr>
                                        <td className="disease-display-td">{ds.id}</td>
                                        <td className="disease-display-td">{ds.nom}</td>
                                        <td className="disease-display-td">{ds.description}</td>
                                        <td className="disease-display-td">
                                            <button id="preview-stages-modal" onClick={() => setStage({
                                                ...stage,
                                                [ds.id]: true
                                            })} >preview stages</button>
                                            <button id="disease-modify-modal" onClick={() => setModalState({
                                                ...modalstate,
                                                [ds.id]: true
                                            })}>modify</button>
                                            <button id="disease-delete-button" onClick={(e) => {
                                                axiosInstance.
                                                    delete('/maladies/' + ds.id).
                                                    then(res => {
                                                        setDisease(disease.filter(d => d.id !== ds.id))

                                                        toast("Disease deleted successfully", {
                                                            type: toast.TYPE.WARNING,
                                                            position: toast.POSITION.BOTTOM_CENTER
                                                        }
                                                        );
                                                    })
                                            }}>delete</button>
                                            <ModifyDiseaseModal id={ds.id} onClose={({ nom, desc, id }) => {
                                                setModalState({
                                                    ...modalstate,
                                                    [ds.id]: false
                                                })
                                                if (nom && desc && id) {

                                                    const index = disease.findIndex(value => value.id === id)

                                                    console.log(index)

                                                    disease[index].nom = nom;
                                                    disease[index].description = desc;


                                                    setDisease([...disease]);
                                                }
                                            }} show={modalstate[ds.id]} />
                                            <PreviewStagesModal id={ds.id} onClose={() => setStage({
                                                ...stage,
                                                [ds.id]: false
                                            })} show={stage[ds.id]} />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default DiseaseMain;