import '../../../components/modals/add-admin/addadmin.css'

const AddAdmin = addAdmin => {

    if(!addAdmin.show){
        return null;
    }

    return ( 
        <div className="add-admin-modal">
            <div className="add-admin-modal-content"> 
                <div className="add-admin-modal-header">
                    <h4 className="add-admin-modal-title">Add admin</h4>
                    <button className="add-admin-modal-header-close-button" onClick={addAdmin.onClose} >x</button>
                </div>
                <div className="add-admin-modal-body">
                    <form action="">
                        <div className="add-admin-modal-email">
                            <input type="email" placeholder="Enter an email address"/>
                        </div>
                        <div className="add-admin-modal-names">
                            <input type="text" placeholder="First name"/>
                            <input type="text" placeholder="Last name"/>
                        </div>
                        <div className="add-admin-modal-password">
                            <input type="password" placeholder="Enter password"/>
                            <input type="password" placeholder="Confirm password"/>
                        </div>
                    </form>
                    <button type="submit" className="add-admin-modal-button">+ Add</button>
                </div>
                <div className="add-admin-modal-footer">
                    <button className="add-admin-modal-footer-cancel-button" onClick={addAdmin.onClose}>cancel</button>
                </div>
            </div>
        </div>
     );
}
 
export default AddAdmin;