import '../../components/modals/DoctorAddPatientModal.css'
import { useState } from 'react';
import { toast } from "react-toastify";
import { axiosInstance } from '../../utils/axios.instance';

const DoctorAddPatientModal = doctorPatient => {
    const [lastName, setLastname] = useState("");
    const [firstName, setFirstname] = useState("");
    const [cin, setCin] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [address, setAddress] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        axiosInstance.post(
            '/patients',
            {
                lastName: lastName,
                firstName: firstName,
                cin: cin,
                email: email,
                number: number,
                address: address
            }
        ).then(res => {
            toast("Successfully added patient", {
                type: toast.TYPE.SUCCESS,
                position: toast.POSITION.BOTTOM_CENTER
            });
        })
    }


    if(!doctorPatient.show){
        return null;
    }

    return ( 
        <div className="doctor-patient-add-modal">
            <div className="doctor-patient-add-modal-content">
                <div className="doctor-patient-add-modal-header">
                    <h4 className="doctor-patient-add-modal-title">Add Patient</h4>
                    <button onClick={doctorPatient.onClose}>x</button>
                </div>
                <div className="doctor-patient-add-modal-body">
                    <form action="" onSubmit={handleSubmit}>
                        <input type="text" placeholder="First name" name="" value={lastName} onChange={(e) => setLastname(e.target.value)} />
                        <br />
                        <input type="text" placeholder="Last name" name="" value={firstName} onChange={(e) => setFirstname(e.target.value)}/>
                        <br />
                        <input type="text" placeholder="cin" name="" value={cin} onChange={(e) => setCin(e.target.value)}/>
                        <br />
                        <input type="email" placeholder="email" name="" value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <br />
                        <input type="email" placeholder="Phone number" name="" value={number} onChange={(e) => setNumber(e.target.value)}/>
                        <br />
                        <input type="text" placeholder="address" name="" value={address} onChange={(e) => setAddress(e.target.value)}/>
                        <br />
                        <div className="doctor-patient-add-modal-footer">
                            <button type="button" className="doctor-patient-add-modal-close-button" onClick={doctorPatient.onClose}>close</button>
                            <button type="submit" className="doctor-patient-add-modal-submit-button">submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
     );
}
 
export default DoctorAddPatientModal;