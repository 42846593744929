import '../components/assistantNavbar.css' ;
import logo from '../assets/logo.png';
import admin_home_icon from '../assets/admin_home_icon.png';
import admin_navbar_home from '../assets/admin_navbar_home.png';
import admin_navbar_dashboard from '../assets/admin_navbar_dashboard.png';
import admin_navbar_doctor from '../assets/admin_navbar_doctor.png';
import admin_navbar_assistant from '../assets/admin_navbar_assistant.png';
import admin_navbar_disease from '../assets/admin_navbar_disease.png';
import settings_icon from '../assets/settings_icon.png';
import signout_icon from '../assets/signout_icon.png';
import { axiosInstance } from '../utils/axios.instance'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom';

const AdminNavbar = () => {
    return ( 
        <div className="col-3" >
           <img src={logo} alt="inquisitio-logo" id="logo" width="160" height="60"></img>

            <ul class="admin-menu">
                <li>
                    <a href="/assistant-home">
                        <img src={admin_navbar_home} alt="home-icon"/>
                        <small>Home Assistant</small>
                    </a>
                </li>
                <li>
                <a href="/patient-main">
                        <img src={admin_navbar_dashboard} alt="dashboard-icon"/>
                        <small>Patient Management</small>
                    </a>
                </li>
                <li>
                <a href="">
                        <img src={admin_navbar_doctor} alt="doctor-icon"/>
                        <small>Appointment Management</small>
                    </a>
                </li>
            </ul>

            <hr />

            <ul className="admin-settings">
                <li>
                    <a href="">
                        <img src={settings_icon} alt="settings" width="25px" height="25px"/>
                        <small>settings</small>
                    </a>
                </li>
                <Link to="/" onClick={(e) => {
                        axiosInstance.
                            get('/shared/logout').
                            then(res => {
                                toast("Logout successfully", {
                                    type: toast.TYPE.INFO,
                                    position: toast.POSITION.BOTTOM_CENTER
                                }
                                );
                                window.location.href = "/";
                            })
                            .catch(err => {
                                console.log(err.message);
                            })
                    }}>
                <li>
                <a href="">
                        <img src={signout_icon} alt="sign-out" width="20px" height="20px" />
                        <small>Sign out</small>
                    </a>
                </li>
                </Link>
            </ul>

            <img src={admin_home_icon} id="admin-logo" alt="home" width="50px" height="50px"/>
            <caption>Admin</caption>
        </div>

     );
}
 
export default AdminNavbar;