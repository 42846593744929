import React from 'react';
import DiseaseRestriction from './pages/disease-restriction/DiseaseRestriction';
import DoctorManagement from './pages/doctor-management/DoctorManagement'
import AssistantManagement from './pages/assistant-management/AssistantManagement'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AdminAuth from './pages/admin-auth/Admin_auth';
import LandingPage from './pages/landing-page/LandingPage'
import AdminSettings from './pages/admin-settings/AdminSettings'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AssistantHome from './pages/assistant-home/AssistantHome';
import AssistantPatient from './pages/assistant-patient-management/AssistantPatient';
import DoctorHomePage from './pages/doctor-homepage/DoctorHomePage';
import DoctorPatientManagement from './pages/doctor-patient-management/DoctorPatientManagement'
import DoctorSettings from './pages/doctor-settings/DoctorSettings'
import AdminHomepage from './pages/admin-homepage/AdminHomepage'
import AdminDashboard from './pages/admin-dashboard/AdminDashboard'
import IaAssistant from './pages/ia-assistant/IaAssistant'
import DoctorAi from './pages/doctor-ai/DoctorAi'
import ExpertManagement from './pages/expert-management/ExpertManagement';
import DiseaseRestrictionExp from './pages/disease-restriction-expert/DiseaseRestrictionExp';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="content">
          <Switch>
          <Route exact path="/admin-dashboard">
              <AdminDashboard />
            </Route>
          <Route exact path="/admin-homepage">
              <AdminHomepage />
            </Route>
          <Route exact path="/doctor-settings">
              <DoctorSettings />
            </Route>
          <Route exact path="/admin-settings">
              <AdminSettings />
            </Route>
          <Route exact path="/doctor-homepage">
              <DoctorHomePage />
            </Route>
            <Route exact path="/doctor-patient">
              <DoctorPatientManagement />
            </Route>
            <Route exact path="/admin-auth">
              <AdminAuth />
            </Route>
            <Route exact path="/disease-restriction">
              <DiseaseRestriction />
            </Route>
            <Route exact path="/doctor-management">
              <DoctorManagement />
            </Route>
            <Route exact path="/assistant-management">
              <AssistantManagement />
            </Route>
            <Route exact path="/assistant-home">
              <AssistantHome />
            </Route>
            <Route exact path="/patient-main">
              <AssistantPatient />
            </Route>
            <Route exact path="/ia-assistant">
              <IaAssistant />
            </Route>
            <Route exact path="/doctor-ai">
              <DoctorAi />
            </Route>
            <Route exact path="/expert-management">
              <ExpertManagement />
            </Route>
            <Route exact path="/expert-disease">
              <DiseaseRestrictionExp />
            </Route>
            <Route exact path="/">
              <LandingPage />
            </Route>
          </Switch>
        </div>
      </div>
      <ToastContainer />
    </Router>
  );
}

export default App;
