import '../../pages/doctor-homepage/css/doctorHomepage.css';
import DoctorNavBar from "../../components/doctor-navbar/DoctorNavbar";
import DoctorHomepageMain from '../../components/doctor-homepage-main/DoctorHomepageMain';

const DoctorHomePage = () => {
    return ( 
        <div className="row-doctor">
            <DoctorNavBar/>
            <DoctorHomepageMain/>
        </div>
     );
}
 
export default DoctorHomePage;