import '../../components/modals/DoctorModifyPatientModal.css'

const DoctorModifyPatientModal = doctorModifyPatient => {

    if(!doctorModifyPatient.show){
        return null;
    }

    return ( 
        <div className="doctor-patient-modify-modal">
        <div className="doctor-patient-modify-modal-content">
            <div className="doctor-patient-modify-modal-header">
                <h4 className="doctor-patient-modify-modal-title">Modify Patient</h4>
                <button onClick={doctorModifyPatient.onClose}>x</button>
            </div>
            <div className="doctor-patient-modify-modal-body">
                <form action="">
                    <input type="text" placeholder="First name" name="" />
                    <br />
                    <input type="text" placeholder="Last name" name=""/>
                    <br />
                    <select name="" id="doctor-patient-gender-select">
                        <option selected>select a gender</option>
                        <option value="1">male</option>
                        <option value="2">female</option>
                    </select>
                    <br />
                    <input type="text" placeholder="cin" name=""/>
                    <br />
                    <input type="date" placeholder="birthday" name=""/>
                    <br />
                    <input type="email" placeholder="email" name=""/>
                    <br />
                    <input type="email" placeholder="Phone number" name=""/>
                    <br />
                    <input type="text" placeholder="address" name=""/>
                    <br />
                    <input type="text" placeholder="height" />
                    <br />
                    <input type="text" placeholder="weight" />
                </form>
            </div>
            <div className="doctor-patient-modify-modal-footer">
                <button type="button" className="doctor-patient-modify-modal-close-button" onClick={doctorModifyPatient.onClose}>close</button>
                <button type="submit" className="doctor-patient-modify-modal-submit-button">submit</button>
            </div>
        </div>
    </div>
     );
}
 
export default DoctorModifyPatientModal;