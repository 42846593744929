import AdminNavbar from '../../components/AdminNavbar'
import './css/main.css';
import { useState, useEffect } from 'react';
import { axiosInstance } from '../../utils/axios.instance';
import ExpertMain from '../../components/expert-management-main/ExpertMain';

const ExpertManagement = () => {

    const [loading, setLoading] = useState(false);
    const [expert, setExpert] = useState([]);
    const [adminProfile, setAdminProfile] = useState({});

    useEffect(() => {
        setLoading(true);

        axiosInstance.get('/admins/me/details')
            .then(res => {

                setLoading(false);
                setAdminProfile(res.data);

                axiosInstance.get('/experts')
                    .then(res => {
                        setExpert(res.data);
                        console.log(res.data);
                        setLoading(false);
                    })
                    .catch(err => {
                        console.log(err);
                        setLoading(false);
                    });

            })
            .catch(error => {
                window.location.href = "/admin-auth";
            });
    }, []);
    
    return (
        <div className="row">
            <AdminNavbar />
            <div className="loading">
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
            <ExpertMain expert={expert} setExpert={setExpert} profile={adminProfile}/>
        </div>
    );
}

export default ExpertManagement;