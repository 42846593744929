import AdminNavbar from '../../components/AdminNavbar'
import AdminDashboardMain from '../../components/admin-dashboard-main/AdminDashboardMain';
import '../../pages/admin-dashboard/css/admindashboard.css'
import { axiosInstance } from '../../utils/axios.instance';
import { useState, useEffect } from 'react';
const AdminDashboard = () => {

    const [adminProfile, setAdminProfile] = useState({});
    const [loading, setLoading] = useState(false);

      // load data
      useEffect(() => {
        setLoading(true);

        /**
         * Check if the user is logged in
         */
        axiosInstance.get('/admins/me/details')
            .then(res => {
                setLoading(false);
                setAdminProfile(res.data);

            })
            .catch(error => {
                window.location.href = "/admin-auth";
            });
    }, []);

    return ( 
        <div className="admin-dashboard-row">
            <AdminNavbar/>
            <AdminDashboardMain profile={adminProfile}/>
        </div>
     );
}
 
export default AdminDashboard;