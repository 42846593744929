import '../../components/landing-page-testimony/testimony.css'
import { useState } from 'react';
import Slider from 'react-slick';
import Firsttweet from '../../assets/firsttweet.png'
import Secondtweet from '../../assets/secondtweet.png'
import Thirdtweet from '../../assets/thirdtweet.png'
import {FaArrowRight, FaArrowLeft} from "react-icons/fa"
  
const images = [Firsttweet, Secondtweet, Thirdtweet]

const Testimony = () => {

    const NextArrow = ({onClick}) => {
        return(
            <div className="arrow next" onClick={onClick}>
                <FaArrowRight/>
            </div>
        )
    }

    const PrevArrow = ({onClick}) => {
        return(
            <div className="arrow prev" onClick={onClick}>
                <FaArrowLeft/>
            </div>
        )
    };

    const [ImageIndex, setImageIndex] = useState(0)

    const settings = {
        infinite: true,
        lazyLoad: true,
        speed: 300,
        SlidesToShow: 3,
        centerMode: true,
        centerPadding:0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow/>,
        beforeChange: (current, next) => setImageIndex(next)
    };

    return ( 
        <div className="testimony-row" id="testimony">
            <div className="testing-row-header">
                <h4>testimony from our <br /> dearest customers</h4>
                
            </div>
            <div className="testimony-carousel">
                <Slider {...settings}>
                    {images.map((img , idx) => (
                        <div  className={idx == ImageIndex ? "slide activeSlide" : "slide"  }>
                            <img src={img} alt={img} style={{width: '650px', height: '420px'}}/>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
     );
}
 
export default Testimony;