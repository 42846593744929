import { axiosInstance } from '../../../utils/axios.instance';
import './ModifyDoctorModal.css'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react';


const ModifyDiseaseModal = modif => {

    const [login, setLogin] = useState("");
    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [specialite, setSpecialite] = useState("");

    useEffect(() => {
        axiosInstance.get('/medecins/' + modif.id).then(res => {
            setLogin(res.data.email);
            setfirstName(res.data.firstName);
            setlastName(res.data.lastName);
            setSpecialite(res.data.specialite);
        })
    }, [modif.id]);

    const handleSubmit = (e) => {
        e.preventDefault();

        axiosInstance
        .put('/medecins/' + modif.id, {
            id: modif.id,
            firstName: firstName,
            lastName: lastName,
            email: login,
            specialite: specialite
        })
        .then(res => {
            console.log("UPDATE---------------", res.data);

            toast("Updated doctors", {
                type: toast.TYPE.SUCCESS,
                position: toast.POSITION.BOTTOM_CENTER
            })

            modif.onClose({
                firstName: firstName,
                lastName: lastName,
                email: login,
                specialite: specialite
            });
            
        })
        .catch(err => {
            console.log(err.message)
        })
    }


    if(!modif.show){
        return null;
    }

    return ( 
        <div className="modify-modal">
        <div className="modify-modal-content">
            <div className="modify-modal-header">
                <h4 className="modify-modal-title">Modify Doctor</h4>
                <button onClick={modif.onClose}>x</button>
            </div>
            <div className="modify-modal-body">
                <form action="" onSubmit={handleSubmit}>
                    <label>Login:</label>
                    <input type="email" placeholder="example@email.com" name="" value={login} onChange={(e) => setLogin(e.target.value)}  />
                    <br />
                    <label>First name:</label>
                    <input type="text" placeholder="First name" name=""  value={firstName} onChange={(e) => setfirstName(e.target.value)}/>
                    <br />
                    <label>Last name:</label>
                    <input type="text" placeholder="Last name" name=""  value={lastName} onChange={(e) => setlastName(e.target.value)}/>
                    <br />
                    <label>Speciality:</label>
                    <input type="text" placeholder="Spéciality" name=""  value={specialite} onChange={(e) => setSpecialite(e.target.value)}/>
                    <br />
                    <div className="modify-modal-footer">
                        <button type="button" className="close-button" onClick={modif.onClose}>close</button>
                        <button type="submit" className="submit-button">update</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
     );
}
 
export default ModifyDiseaseModal;