
import '../../components/doctor-patient/doctorPatientMain.css'
import DoctorAddPatientModal from "../../components/modals/DoctorAddPatientModal";
import DoctorModifyPatientModal from "../../components/modals/DoctorModifyPatientModal";
import { axiosInstance } from '../../utils/axios.instance'
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify'

const DoctorPatientMain = () => {

    const [doctorPatient, setdoctorPatient] = useState(false) 
    const [doctorModifyPatient, setModifydoctorPatient] = useState(false) 

    return ( 
        <div className="doctor-patient-col-9">
                <header className="doctor-patient-header">
                    <h3>Patient Management</h3>
                    <h5>Welcome, John!</h5>
                    <form action="">
                        <input type="search" placeholder="Search Something..." name="search" id="search-bar" />
                    </form>
                </header>

                <section className="doctor-patient-management-intro">
                    <h4>Patient Management</h4>
                    <h5>Manage all your patients from the section below.</h5>
                </section>

                <div className="doctor-patient-management-functionalities">
                    <form action="">
                        <input type="search" placeholder="Search patient" name="search-patient" id="doctor-patient-search-bar" />
                    </form>
                    <button type="button" className="doctor-patient-add-modal-button" onClick={ () => setdoctorPatient(true)}> + Add patient</button>
                    <DoctorAddPatientModal onClose={ () => setdoctorPatient(false)} show={doctorPatient}/>
                    <section className="doctor-patient-pagination">
                        <button type="button" id="doctor-patient-previous-page">Previous</button>
                        <button type="button" id="doctor-patient-next-page">Next</button>
                    </section>

                </div>

                <div className="doctor-patient-display">
                        <table>
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>last name</th>
                                    <th>first name</th>
                                    <th>cin</th>
                                    <th>email</th>
                                    <th>number</th>
                                    <th>address</th>
                                    <th>action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>h</td>
                                    <td>h</td>
                                    <td>h</td>
                                    <td>h</td>
                                    <td>h</td>
                                    <td>h</td>
                                    <td>h</td>

                                    <td className="doctor-patient-management-actions">
                                        <button id="doctor-patient-modify-modal" onClick={ () => setModifydoctorPatient(true)}>modify</button>
                                        
                                        <button id="doctor-patient-delete-button">delete</button>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                    <DoctorModifyPatientModal onClose={ () => setModifydoctorPatient(false)} show={doctorModifyPatient}/>
        </div>
     );
}
 
export default DoctorPatientMain;