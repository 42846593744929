import AssistantNavbar from '../../components/AssistantNavbar'
import './css/main.css';
import { useState, useEffect } from 'react';
import { axiosInstance } from '../../utils/axios.instance';
import PatientMain from '../../components/assistant-patient-management/PatientMain';


const AssistantPatient = () => {

    
    return (
        <div className="row">
            <AssistantNavbar />
            <div className="loading">
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
            <PatientMain/>
            
        </div>
    );
}

export default AssistantPatient;