import DoctorNavBar from "../../components/doctor-navbar/DoctorNavbar";
import DoctorPatientMain from "../../components/doctor-patient/DoctorPatientMain"
import '../../pages/doctor-patient-management/css/doctorpatientmanagement.css';
import { useState, useEffect } from 'react';
import { axiosInstance } from '../../utils/axios.instance';

const DoctorPatientManagement = () => {

    const [loading, setLoading] = useState(false);
    const [patient, setPatient] = useState([]);

    // load data
    useEffect(() => {

                axiosInstance.get('/patients')
                    .then(res => {
                        setPatient(res.data);
                        console.log(res.data);
                    })
                    .catch(err => {
                        console.log(err);
                    });

    });

    return ( 
        <div className="doctor-patient-management-row">
            <DoctorNavBar/>

            {
                loading ?
                    <div className="loading">
                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                    :
                    <DoctorPatientMain  />
            }
        
        </div>
     );
}
 
export default DoctorPatientManagement;