import '../../components/modals/SignInModal.css'
import { useState } from 'react';
import { axiosInstance } from '../../utils/axios.instance';
import { toast } from 'react-toastify';

const SignInModal = access => {

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [selectedValue, setSelectedValue] = useState("");

    const changeProfile = (e) => {
        e.preventDefault();

        setSelectedValue(e.target.value);

    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if(selectedValue === '1'){
            axiosInstance.post(
                '/medecins/login',
                {
                    login,
                    password
                }
            ).then(res => {
                toast("Doctor successfully logged in", {
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_CENTER
                });
    
                setTimeout(() => {
                    window.location.href = "/doctor-homepage";
                }, 1000);
            })
                .catch(error => {
                    toast("Invalid credentials", {
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    setLogin("");
                    setPassword("");
                })

        }else if(selectedValue === '2'){
            axiosInstance.post(
                '/secretaire/login',
                {
                    login,
                    password
                }
            ).then(res => {
                toast("Assistant successfully logged in", {
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_CENTER
                });
    
                setTimeout(() => {
                    window.location.href = "/assistant-home";
                }, 1000);
            })
                .catch(error => {
                    toast("Invalid credentials", {
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    setLogin("");
                    setPassword("");
                })
        }else if(selectedValue == '3'){
            axiosInstance.post(
                '/experts/login',
                {
                    login,
                    password
                }
            ).then(res => {
                toast("Expert successfully logged in", {
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_CENTER
                });
    
                setTimeout(() => {
                    window.location.href = "/expert-homepage";
                }, 1000);
            })
                .catch(error => {
                    toast("Invalid credentials", {
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    setLogin("");
                    setPassword("");
                })

        }
    }

    if(!access.show){
        return null;
    }

    return ( 
        <div className="sign-in-modal">
            <div className="sign-in-modal-content">
                <div className="sign-in-modal-header">
                    <h4 className="sign-in-modal-title">Sign in</h4>
                    <button id="close-button" onClick={access.onClose} >x</button>
                </div>
                <div className="sign-in-modal-body">
                    <div className="message">
                        <p className="first">Welcome to the <span>Inquisitio</span> login portal.</p>
                        <p className="second">This portal is restricted to doctors and assitants only.</p>
                    </div>
                    <form action="" id="sign-in-form"  onSubmit={handleSubmit}>
                        <select className="profile-option" defaultValue={selectedValue} onChange={changeProfile}>
                            <option selected>I am a...</option>
                            <option value="1">Doctor</option>
                            <option value="2">Assistant</option>
                            <option value="3">Expert</option>
                        </select>
                        <div className="sign-in-inputs">
                            <input type="text" placeholder="username" name="username" id="username" className="text-fields" value={login} onChange={(e) => setLogin(e.target.value)} />
                        </div>
                        <div className="sign-in-inputs">
                            <input type="password" placeholder="password" name="password" id="password" className="text-fields" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className="remember">
                            <input type="checkbox" id="remember-checkbox" />
                            <span>Remember me</span> 
                            <button type="submit" id="login-btn"> sign in</button>
                        </div>

                       
                    </form>
                </div>
            </div>
        </div>
     );
}
 
export default SignInModal;