import '../../components/doctor-navbar/doctorNavbar.css'
import logo from '../../assets/logo.png';
import admin_navbar_home from '../../assets/admin_navbar_home.png';
import patient_management from '../../assets/patient_management.png';
import schedule from '../../assets/schedule.png';
import settings_icon from '../../assets/settings_icon.png';
import signout_icon from '../../assets/signout_icon.png';
import aiAssistant from '../../assets/ai_assistant.png'
import { axiosInstance } from '../../utils/axios.instance';
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom';

const DoctorNavBar = () => {
    return ( 
        <div className="doctor-col-3" >
        <img src={logo} alt="inquisitio-logo" id="logo" width="160" height="60"></img>

         <ul class="doctor-menu">
             <li>
                 <a href="/doctor-homepage">
                     <img src={admin_navbar_home} alt="home-icon"/>
                     <small>Home</small>
                 </a>
             </li>
             <li>
             <a href="/doctor-patient">
                     <img src={patient_management} alt="patient-icon"/>
                     <small>Patient Management</small>
                 </a>
             </li>
             <li>
             <a href="/doctor-rendezvous">
                     <img src={schedule} alt="schedule-icon" width="23px"  height="23px"/>
                     <small>Appointment Management</small>
                 </a>
             </li>
             <li>
                <a href="/doctor-ai">
                        <img src={aiAssistant} alt="ai-icon"  width="25px"  height="23px"/>
                        <small>Ai assistant</small>
                    </a>
                </li>
         </ul>
         <hr />

         <ul className="doctor-settings">
             <li>
                 <a href="/doctor-settings">
                     <img src={settings_icon} alt="settings" width="25px" height="25px"/>
                     <small>settings</small>
                 </a>
             </li>
             <Link to="/" onClick={(e) => {
                        axiosInstance.
                            get('/shared/logout').
                            then(res => {
                                toast("Logout successfully", {
                                    type: toast.TYPE.INFO,
                                    position: toast.POSITION.BOTTOM_CENTER
                                }
                                );
                                window.location.href = "/";
                            })
                    }}>
             <li>
             <a href="">
                     <img src={signout_icon} alt="sign-out" width="20px" height="20px" />
                     <small>Sign out</small>
                 </a>
             </li>
             </Link>
         </ul>

     </div>

     );
}
 
export default DoctorNavBar;